<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"><i class="fa fa-home"></i></b-breadcrumb-item>
            <b-breadcrumb-item active>門市開團列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">門市開團列表</h4>
        </div>
      </div>
      <router-link :to="{ name: 'StoreGroupbuyingUpdateImage', params: { id: 1 } }">Edit</router-link>
    </b-card>
  </div>
</template>

<script>
  export default {
    //
  }
</script>
